.modal-content
{
    width:100%;
}

@media (min-width: 576px)
{
    .modal-dialog {
        max-width: 900px!important;
    }
}

.modal-templates {
    width: 90%;
    min-width: 90%;
    /* max-width: 90%; */
}

.my-editing-area {
    min-height: 100%;
}